import React from "react";
import "./header.css";
import {useEffect} from "react";

function Header() {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <>
      <header className="header-container">
        <div class="container">
          <div className="header-left-content">
            <p>Welcome to ReveMax</p>
          </div>
          <div className="header-right-content">
            <ul>
              <li>
                {/* <a href="http://+1-647-616-2625">
                  <i className="fa fa-phone"></i>
                  +1-647-616-2625
                </a> */}
                <a href="tel:+1647-616-2625">
                <i className="fa fa-phone"></i>
                  +1 647-616-2625
                  </a>
              </li>

              <li>
                <a href="mailto:contact@revemax.ai">
                  <i className="fa fa-envelope"></i>
                  contact@revemax.ai
                </a>
              </li>
            </ul>
          </div>
        </div>
          
      </header>
    </>
  );
}

export default Header;
