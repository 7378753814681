import React from "react";
import pricing from "./images/pricing.jpg";
import pricing1 from "./images/pricing1.png";
import economics from "./images/economics.jpg";
import { Link } from "react-router-dom";
import "./Pricing.css";
import {useEffect} from "react";

function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0)
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])
  return (
    <div className="pricing-container">
      <div
        className="news-banner-area"
        style={{ background: `url(${economics})` }}
      >
        <div className="news-content container">
          <div className="news-links">
            <h2 className="news-h2">RETHINKING THE PRICING DYNAMIC</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
              </li>
              <li>
                <Link to="/news" className="home-link">
                  Blog
                </Link>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
              </li>
              
            </ul>
            <p>RETHINKING THE PRICING DYNAMIC</p>
          </div>
        </div>
      </div>

      <div className="news-content-container container">
        <div className="news-left-content">
          <img src={pricing1} className="img-pricing" />
          <p className="pricing-para">
            <strong>Posted On:</strong> February 20, 2021{" "}
            <strong>Posted By:</strong> <Link to="/author">Hugh Dunleavy</Link>
          </p>
          <p className="pricing-para">
            Pre-pandemic, air travel in the USA was relatively expensive
            compared with travel on similar sector length international routes.
            The airline profit/loss airline cycle has deteriorated with ever
            more fees and resulted in consolidation in the US and the creation
            of the Big 3 (UA, AA and DL) with virtual monopolies at their major
            hubs. The next tier of airline players may be significant in
            geographic regions but do not provide a comprehensive US wide
            network and may not have international airline partners.
          </p>
          <p className="pricing-para">
            As such they are not options for customers that need a domestic and
            international sector. One can consider a scenario where any of the
            Big 3 carriers identifies a mutual win-win-win by not playing
            competitive pricing games in the other airline’s major hubs. The Big
            3 would price aggressively in all non-hub markets. Effectively using
            hub-dominance to generate excess revenue to support lower fares on
            non-hub routes and negatively impact the revenue stream of the next
            tier of airlines.
          </p>
          <p className="pricing-para">
            The anti-trust regulations may not have been violated, but
            effectively the airline consolidation process has negated the
            original intent of the anti-trust regulations. As airlines emerge
            from the pandemic, it may be an opportunity to establish a new
            network and pricing dynamic.
          </p>
          <p className="pricing-para">
            <strong>#pricingstrategy</strong>
          </p>
          <div className="comment-form-container">
            <p>
              <strong>Leave a Reply</strong>
            </p>
            <p>
              Your email address will not be published. Required fields are
              marked *
            </p>
            <form
              className="comment-form"
              action="https://revemax.ai/wp-comments-post.php"
              method="post"
              id="commentform"
              class="comment-form"
              novalidate=""
            >
              <div className="comment-section">
                <label htmlFor="comment">Comment</label>
                <textarea
                  className="textarea"
                  rows="5"
                  cols="40"
                  required
                ></textarea>
              </div>
              <div className="name-email">
                <div className="name-section">
                  <label htmlFor="name">Name *</label>
                  <input type="text" name="name" required />
                </div>
                <div className="email-section">
                  <label htmlFor="email">Email *</label>
                  <input type="email" name="email" required />
                </div>
              </div>
              <div className="website-section">
                <label htmlFor="website">Website</label>
                <input type="text" name="website" required />
              </div>
              <div className="checkbox-container">
                <input type="checkbox" id="check" />
                <p>
                  Save my name, email, and website in this browser for the next
                  time I comment
                </p>
              </div>
              <div className="post-btn">
                <input
                  type="SUBMIT"
                  className="comment-btn"
                  value="POST A COMMENT"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="news-right-content">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search here..."
              className="search-input"
            />
            <i class="fa fa-search"></i>
          </div>
          <div className="annoucements">
            <h2 className="widget-title">Annoucements</h2>
            <Link to="/malaysiaairlines" className="annoucement-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
          </div>
          <div className="recent-posts">
            <h2 className="widget-title">Recent Posts</h2>
            <Link to="/malaysiaairlines" className="news-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
            <Link to="/pricing" className="news-link">
              RETHINKING THE PRICING DYNAMIC
            </Link>
            <Link to="/economics" className="news-link">
              AIRLINE ECONOMICS DURING THE TRANSITION TO A POST-PANDEMIC WORLD
            </Link>
            <Link to="/crisis" className="news-link">
              CRISIS AT THE SUMMIT – AVIATION’S SILVER LINING WITH A LIGHT AT
              THE END OF THE TUNNEL
            </Link>
          </div>
          <div className="recent-comments">
            <h2 className="widget-title">Recent Comments</h2>
          </div>
          <div className="categories">
            <h2 className="widget-title">Categories</h2>
            <Link to="/uncategorized" className="news-link">
              Uncategorized
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
