import React, { useState } from "react";
import "./Solution.css";
import { useEffect } from "react";
import {Link} from "react-router-dom";
import prediction1 from "./images/prediction1.jpg";
import analysis1 from "./images/analysis1.png";
import reporting1 from "./images/reporting1.png";

function Solution() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabChange = (tabName) => {
    var tabs = document.querySelectorAll(".list-container .prediction");
    var tabContainers = document.querySelectorAll(".card-container");
    tabContainers.forEach(element => {
      if (tabName === element.id){
        element.style.display = "block";
      }else{
        element.style.display = "none";
      }
    });
    tabs.forEach(element => {
      if (tabName === element.id){
        element.classList.add("active");
      }else{
        element.classList.remove("active");
      }
    });
    
  }
  return (
    <div className="solution container">
      <h1 className="solution-heading">
        Airline Revenue Maximization Solution.
      </h1>
      <div className="solution-container">
        <div className="list-container">
          <div className="prediction active" id="predictBtn" onClick={() => handleTabChange('predict')}>
            <i class="fa fa-search"></i>Prediction
          </div>

          <div className="prediction" id="analysisBtn" onClick={() => handleTabChange('analysis')}>
            <i class="fa fa-bar-chart"></i>Analysis
          </div>

          <div className="prediction" id="reportingBtn" onClick={() => handleTabChange('reporting')}>
            <i class="fa fa-pie-chart"></i>Reporting
          </div>
        </div>
        <div className="card-container" id="predict" >
          <img src={prediction1} alt="prediction"></img>
          <p>
            Prediction Revemax is helping clients understand the complexities
            and bring in an intelligence to maximize their revenue in this
            competitive world, by working together to bring a solution which can
            put their organization at the competitive edge. Predictive analytics
            is often discussed in the context of big data. Business data at a
            company might include
          </p>
          <Link to="/prediction" className="readmore-button">
            Read More
          </Link>
        </div>
        <div className="card-container" id="analysis">
          <img src={analysis1} alt="analysis"></img>
          <p>
            Analysis Using data analysis, enables you to understand what
            strategy have the biggest impact on the targeted entity and at what
            scale you can adopt such a move to Maximize. There is no activity
            that data analysis does better than to check the performance of your
            products or business in the market. Once you understand
          </p>
          <Link to="/prediction" className="readmore-button">
            Read More
          </Link>
        </div>
        <div className="card-container" id="reporting">
          <img src={reporting1} alt="reporting"></img>
          <p>
            Reporting Business reports document the progress of your business
            and the data collected serves several important purposes. It guides
            strategic decision making, helping business leaders to formulate
            budget and planning activities for the ensuing year using the report
            data to back choices and provide justification for each decision,
            Monitoring and reporting over time will not
          </p>
          <Link to="/prediction" className="readmore-button">
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Solution;
