import React from "react";
import "./Ecosystem.css";
import image1 from "./images/image1.png";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import image4 from "./images/image4.png";
import image5 from "./images/image5.png";
import {useEffect} from "react";

function Ecosystem() {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <div className="eco-container container">
      <div className="eco-heading">
        <h1>Our Business Ecosystem</h1>
        <p>No company exists in a vacuum; each is part of an ecosystem.</p>
      </div>
      <dic className="slide-container">
      <div className="eco-image-heading">
          <div className="eco-image">
            <img src={image1} alt="" />
          </div>
          <div className="eco-image">
            <img src={image2} alt="" />
          </div>
          <div className="eco-image">
            <img src={image3} alt="" />
          </div>
          <div className="eco-image">
            <img src={image4} alt="" />
          </div>
          <div className="eco-image">
            <img src={image5} alt="" />
          </div>
          <div className="eco-image">
            <img src={image1} alt="" />
          </div>
          <div className="eco-image">
            <img src={image2} alt="" />
          </div>
          <div className="eco-image">
            <img src={image3} alt="" />
          </div>
          <div className="eco-image">
            <img src={image4} alt="" />
          </div>
          <div className="eco-image">
            <img src={image5} alt="" />
          </div>
      </div>
      </dic>
    </div>
  );
}

export default Ecosystem;
