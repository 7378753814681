import React from 'react'
import "./Reachout.css";
import woman from "./images/woman.jpg";
import shade from "./images/2.png";
import {useEffect} from "react";

function Reactout() {
    useEffect(() => {
        window.scrollTo(0, 0)
      },[])
    return (
        <div className="reachout-container reach-bg">
            <div className="woman-container" style={{ backgroundImage: 'url('+woman+')' }}>
                <img src={woman} alt=""/>
            </div>
            <div className="reach-text">
               <h1>Reach out to us</h1>
               <h4>contact@revemax.ai</h4>
               <p>Let us bring your Airline Strategy into the Digital World</p>
            </div>
        </div>
    )
}

export default Reactout
