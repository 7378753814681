import React from "react";
import { Link } from "react-router-dom";
import "./Emptyseat.css";
import emptyseat from "../../images/emptyseat.jpg";
import {useEffect} from "react";

function Emptyseat() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])
  return (
    <div className="emptyseat-container">
      <div
        className="emptyseat-banner-area"
        style={{ background: `url(${emptyseat})` }}
      >
        <div className="emptyseat-content container">
          <div className="emptyseat-links">
            <h2 className="emptyseat-h2">Empty seat option</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>Empty seat option</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="emptyseat-content container">
        <div className="emptyseat-heading">
          <i class="fa fa-scissors"></i>
          <h2 className="emptyseat-title">Empty seat option</h2>
        </div>
        <p className="emptyseat-para">
          The solution enables customers to buy 1 or more empty seats next to
          them for enhanced comfort. In the current Covid-19 environment this
          option becomes critical. This option is important to several customer
          segments like business travelers seeking comfortable space to work or
          discuss, families traveling with kids or babies, couples desiring
          privacy, etc. may desire to purchase empty seats. ReveMax’s algorithms
          modify bookings while optimizing seat capacity and ensuring that
          necessary information is reflected in both Reservation and Departure
          Control systems used by airlines.
        </p>
      </div>
    </div>
  );
}

export default Emptyseat;
