import React from "react";
import { Link } from "react-router-dom";
import news from "./images/news.jpg";
import aeroplane from "./images/aeroplane.png";
import globe from "./images/globe.png";
import pricing from "./images/pricing.png";
import "./News.css";
import {useEffect} from "react";

function News() {
  useEffect(() => {
    window.scrollTo(0, 0)

    const preloader = document.querySelector('.preloader');
    preloader.className = 'preloader preloader-deactivate';

    return () => { preloader.className = 'preloader'; }
  },[])
  return (
    <div className="news-container">
      <div className="news-banner-area" style={{ background: `url(${news})` }}>
        <div className="news-content container">
          <div className="news-links">
            <h2 className="news-h2">News</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
              </li>
              <li>News</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="news-content-container container">
        <div className="news-left-content" id="news-left-content">
          <div className="aeroplane-container my-4">
            <div className="content-news-container">
              <p className="news-para">
                Admin / June 05, 2021 / No Comments
              </p>
              <Link to="/malaysiaairlines" className="article-link">
                Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
                Strategy.
              </Link>
              <p className="news-para">
                KLIA, 17 May 2021: Malaysia Airlines is pleased to announce its
                partnership with ReveMax, a leading provider of Digital
                Strategic Solutions, to implement the Airline Revenue
                Maximization Solution (ARMS) which will enhance and strengthen
                the airline’s commercial decision-making and business processes.
              </p>
              <Link to="/malaysiaairlines" className="read-btn">
                Read More <i class="fa fa-plus"></i>
              </Link>
            </div>
          </div>
          <div className="pricing-container  my-4">
            <img src={pricing} alt="" className="news-image" />
            <div className="content-news-container">
              <p className="news-para">Hugh Dunleavy / February 20, 2021</p>
              <Link to="/pricing" className="article-link">
                RETHINKING THE PRICING DYNAMIC
              </Link>
              <p className="news-para">
                re-pandemic, air travel in the USA was relatively expensive
                compared with travel on similar sector length international
                routes. The airline profit/loss airline cycle has deteriorated
                with ever more fees and resulted in consolidation in the US and
                the creation of the Big 3 (UA, AA and DL)…
              </p>
              <Link to="/pricing" className="read-btn">
                Read More <i class="fa fa-plus"></i>
              </Link>
            </div>
          </div>
          <div className="globe-container  my-4">
            <img src={globe} alt="" className="news-image" />
            <div className="content-news-container">
              <p className="news-para">Hugh Dunleavy / January 20, 2021</p>
              <Link to="/economics" className="article-link">
                AIRLINE ECONOMICS DURING THE TRANSITION TO A POST-PANDEMIC WORLD
              </Link>
              <p className="news-para">
                Let us get right to the point ……. The airline industry is
                fragile due to many factors: · High fixed cost of operations ·
                Low variable cost of operation · Aircraft Purchase decisions
                have to be made for many years into the future. · Business risk
                does not move down the distribution chain. · Extreme sensitivity
                to external events · Government
              </p>
              <Link to="/economics" className="read-btn">
                Read More <i class="fa fa-plus"></i>
              </Link>
            </div>
          </div>
          <div className="aeroplane-container my-4">
            <img src={aeroplane} alt="" className="news-image" />
            <div className="content-news-container">
              <p className="news-para">Nayeem Ekbal / March 20, 2020</p>
              <Link to="/crisis" className="article-link">
                CRISIS AT THE SUMMIT – AVIATION’S SILVER LINING WITH A LIGHT AT
                THE END OF THE TUNNEL
              </Link>
              <p className="news-para">
                Civil aviation has become a major industry in our time. Without
                air travel, mass international tourism would not exist..
              </p>
              <Link to="/crisis" className="read-btn">
                Read More <i class="fa fa-plus"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="news-right-content" id="news-right-content">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search here..."
              className="search-input"
            />
            <i class="fa fa-search"></i>
          </div>
          <div className="annoucements">
            <h2 className="widget-title">Annoucements</h2>
            <Link to="/malaysiaairlines" className="annoucement-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
          </div>
          <div className="recent-posts">
            <h2 className="widget-title">Recent Posts</h2>

            <Link to="/malaysiaairlines" className="news-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
            <Link to="/pricing" className="news-link">RETHINKING THE PRICING DYNAMIC</Link>
            <Link to="/economics" className="news-link">
              AIRLINE ECONOMICS DURING THE TRANSITION TO A POST-PANDEMIC WORLD
            </Link>
            <Link to="/crisis" className="news-link">
              CRISIS AT THE SUMMIT – AVIATION’S SILVER LINING WITH A LIGHT AT
              THE END OF THE TUNNEL
            </Link>
          </div>
          <div className="recent-comments">
            <h2 className="widget-title">Recent Comments</h2>
          </div>
          <div className="categories">
            <h2 className="widget-title">Categories</h2>
            <Link to="/uncategorized" className="news-link">Uncategorized</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
