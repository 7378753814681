import React from "react";
import { Link } from "react-router-dom";
import "./Economics.css";
import economics from "./images/economics.jpg";
import economics1 from "./images/economics1.png";
import { useEffect } from "react";
function Economics() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  }, []);
  return (
    <div className="economics-container">
      <div
        className="news-banner-area"
        style={{ background: `url(${economics})` }}
      >
        <div className="news-content container">
          <div className="news-links">
            <h2 className="news-h2">
              AIRLINE ECONOMICS DURING THE TRANSITION TO A POST-PANDEMIC WORLD
            </h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
              </li>
              <li>
                <Link to="/news" className="home-link">
                  Blog
                </Link>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
              </li>
            </ul>
            <p>
              AIRLINE ECONOMICS DURING THE TRANSITION TO A POST-PANDEMIC WORLD
            </p>
          </div>
        </div>
      </div>
      <div className="news-content-container container">
        <div className="news-left-content">
          <img src={economics1} className="img-economics" />
          <p className="pricing-para">
            <strong>Posted On:</strong> January 20, 2021{" "}
            <strong>Posted By:</strong> Hugh Dunleavy
          </p>
          <p className="pricing-para">Let us get right to the point …….</p>
          <p className="pricing-para">
            The airline industry is fragile due to many factors:
          </p>
          <p className="pricing-para">
            <p>
              <strong>
                ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;High fixed cost of
                operations
              </strong>
            </p>
            <p>
              <strong>
                ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Low variable cost of
                operation
              </strong>
            </p>
            <p>
              <strong>
                ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aircraft Purchase
                decisions have to be made for many years into the future.
              </strong>
            </p>
            <p>
              <strong>
                ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Business risk does not move
                down the distribution chain.
              </strong>
            </p>
            <p>
              <strong>
                ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Extreme sensitivity to
                external events
              </strong>
            </p>
            <p>
              <strong>
                ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Government Regulations and
                Oversight
              </strong>
            </p>
            <p>
              <strong>
                ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;International trade
                agreements
              </strong>
            </p>
            <p>
              <strong>
                ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Natural monopolies at
                airports and limitations on economically efficient airport slots
              </strong>
            </p>
            <p>
              <strong>
                ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Typically, very low margin
                business with a tendency towards a boom or bust cycle.
              </strong>
            </p>
          </p>
          <p className="pricing-para">
            The global pandemic is just one example of the extreme sensitivity
            of the airline business model to events that are beyond the
            airline’s control. In the case of the pandemic the problem is
            compounded by additional factors that handicap the airline industry
            in its attempt to restart business.
          </p>
          <p>
            <strong>
              ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Severity of the virus
              transmissibility.
            </strong>
          </p>
          <p>
            <strong>
              ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New virus variants may be
              even more transmissible and potentially more deadly.
            </strong>
          </p>
          <p>
            <strong>
              ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Transmission control actions
              at the national level are mixed.
            </strong>
          </p>
          <p>
            <strong>
              ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Availability of an effective
              vaccine is constrained by production and distribution.
            </strong>
          </p>
          <p>
            <strong>
              ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Population behavior in
              accepting the vaccine.
            </strong>
          </p>
          <p>
            <strong>
              ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Government rules and
              restrictions on airline travel
            </strong>
          </p>
          <p>
            <strong>
              ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Potential new breakouts of
              the infection as restrictions are eased.
            </strong>
          </p>
          <p className="pricing-para">
            All these issues introduce a large number of variables for the
            airline to consider when deciding to restart services between
            cities. The historical airline data regarding traditional traffic
            flow patterns will have been disrupted by the collapse in airline
            route networks, the propensity for people to travel will have been
            reduced not only by “stay at home” instructions but also by the
            inevitable safety concerns associated with the air travel process
            (Uncertainty about booking flights for some future date, airport
            processes (check-in, Security lines, Immigration lines, airport
            departure Gates) etc. Social separation requirements are extremely
            difficult to maintain in such settings especially when segments of
            the population may not be as rigorous as others in wearing masks and
            maintaining the recommended separation.
          </p>

          <p className="pricing-para">
            As we know, airlines survive on the cash flow they receive from
            passengers booking flights today for a departure at some time in the
            future. This means that if the passenger advanced booking behavior
            changes due to uncertainty regarding when they can be vaccinated or
            restrictions on travel are lifted, one can expect to see a shift
            from longer range bookings to much closer to departure bookings,
            This has implications on both passenger demand and advanced booking
            revenue (cash flow). It should be noted that small changes in
            passenger demand (1-3%) will have serious implications on the
            viability of many airlines. This in turn will typically trigger
            significant sales actions by airlines in efforts to stimulate sales
            and capture traffic from competitors.
          </p>
          <p className="pricing-para">
            A further challenge will be the mix of traffic observed pre-pandemic
            versus the mix of traffic observed in the post-pandemic world. For
            example, it is projected that the propensity of business travel will
            change as more people become comfortable in using video conferencing
            products such as MS-Teams, Zoom etc. Supporting this projection is
            the recognition that many business segments will emerge form the
            pandemic in a weakened state and in efforts to protect cash-flow
            will enact stricter travel policies that will further weaken demand
            for air travel.
          </p>
          <p className="pricing-para">
            I have noted the potential impact on the business travel segment as
            this segment is relatively small in volume, but extremely important
            to most airlines as they traditional occupy the business cabin on an
            aircraft and typically pay much higher fares than the Economy
            traveler. The economic impact of just a 10% reduction in the
            business segment has a significant long-term negative revenue impact
            on the airline. If we note that the airline has already invested in
            the business cabin configuration (cost has already been incurred),
            operating with empty business seats is not feasible and filling
            these seats with paid upgrades from the Economy cabin may not offset
            the lost revenue from business class fares. An approach that may be
            adopted by airlines is to promote the benefits of the extra space
            available to appeal to more safety conscious travelers. This however
            is a two- edged sword.
          </p>
          <p className="pricing-para">
            The economy cabin is likely to see less impact on demand. This will
            be for several reasons. Visiting Friends and Relatives (VFR) will
            likely see an increased demand as people seek relief from the
            isolation imposed by the pandemic, Vacation travel will recover as
            more and more people are vaccinated and as companies struggle to
            restart their business. It should also be expected that previous
            company policies that allowed business travel privileges are likely
            to become more restricted and staff will be downgraded to Economy
            travel, at least on shorter range flights.
          </p>
          <p className="pricing-para">
            Airlines are likely to struggle through 2021 and 2022 even as the
            pandemic is brought more and more under control. In this extended
            recovery period, Travel patterns will continue to change, demand
            volume will change, and all the historical data sets that airlines
            used to support decision making, will continue to be suspect as the
            basis for forecasting future travel decisions.
          </p>
          <p>
            <strong>SO, WHAT CAN THE AIRLINES DO?</strong>
          </p>
          <p className="pricing-para">
            One answer is to make more effective use of the existing data sets
            to obtain a more holistic view of how travel patterns are evolving
            as the days to departure reduce. Many airlines still tend to manage
            their business in silos. Network Planning utilize QSI models to
            generate attractive network and schedules where the quality of the
            product and the utilization of each aircraft are major
            considerations in the designing the overall product offering.
          </p>
          <p className="pricing-para">
            This data set is used infrequently outside of Network Planning.
            Similarly, Revenue Management and Pricing utilize booking and
            departure data with competitive pricing metrics to assess how many
            seats to sell at what date and what price. This data is for the most
            part, not utilized outside of Revenue Management. If we now change
            our focus to Sales and Distribution, we observe that this function
            utilizes MIDT/DDS data to assess the airline’s share of the traffic
            in each market, how each distribution channel is performing, and
            which Travel Trade partner is providing the level of business that
            was anticipated when negotiating incentive/compensation agreements.
          </p>
          <p className="pricing-para">
            Of course, the above scenario is accurate to varying degrees,
            depending on the level of sophistication in the data management and
            decision support environment at the airline. Traditionally, the
            availability and integration of these data sources for decision
            making today, still follows a silo-based approach to managing the
            airline business. The reason for this philosophy is because, if the
            complete airline problem is too complex to solve, then break the
            problem down into its component parts and solve the set of simpler
            problems. However, when the solutions for the set of simpler
            problems are combined to address the overall airline problem, there
            is no guarantee that this approach generates an optimal solution of
            the actual full airline problem.
          </p>
          <p className="pricing-para">
            We must also recognize that as airlines emerge from the pandemic,
            they will all be competing aggressively for whatever travelers are
            available in the market. It is anticipated that availability of
            airline seats will exceed the demand for those seats. This will
            inevitably lead to airlines making decisions to try and capture as
            much of the available demand as possible. Network, Schedule, Pricing
            and Seat Availability decisions will all be impacted by the
            perishable nature of the airline seat that drives the imperative to
            “capture passengers from the other airlines. The adage, “You are
            only as smart as your dumbest competitor”, will continue to apply
            and drive airline behavior.
          </p>
          <p>
            <strong>REVEMAX WORKS DIFFERENTLY.</strong>
          </p>
          <p className="pricing-para">
            It brings all the different data silos available in an airline to be
            integrated into a single cloud-based environment. The use of elegant
            and intuitive Dashboards and Drill Down capabilities supported by
            predictive analytics and Alerts, enables an airline decision maker
            to have a more comprehensive and understandable perspective the best
            decision for the Airline. The traditional silo-based view of an
            airline performance is replaced by a more comprehensive and
            integrated view across all of the available data silos. ReveMax
            provides a major step increase in the ability of airlines to manage
            through turbulence and chaos in a rapidly changing world.
          </p>
          <div className="comment-form-container">
            <p>
              <strong>Leave a Reply</strong>
            </p>
            <p>
              Your email address will not be published. Required fields are
              marked *
            </p>
            <form
              className="comment-form"
              action="https://revemax.ai/wp-comments-post.php"
              method="post"
              id="commentform"
              class="comment-form"
              novalidate=""
            >
              <div className="comment-section">
                <label htmlFor="comment">Comment</label>
                <textarea
                  className="textarea"
                  rows="5"
                  cols="45"
                  required
                ></textarea>
              </div>
              <div className="name-email">
                <div className="name-section">
                  <label htmlFor="name">Name *</label>
                  <input type="text" name="name" required />
                </div>
                <div className="email-section">
                  <label htmlFor="email">Email *</label>
                  <input type="email" name="email" required />
                </div>
              </div>
              <div className="website-section">
                <label htmlFor="website">Website</label>
                <input type="text" name="website" required />
              </div>
              <div className="checkbox-container">
                <input type="checkbox" id="check" />
                <p>
                  Save my name, email, and website in this browser for the next
                  time I comment
                </p>
              </div>
              <div className="post-btn">
                <input
                  type="SUBMIT"
                  className="comment-btn"
                  value="POST A COMMENT"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="news-right-content">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search here..."
              className="search-input"
            />
            <i class="fa fa-search"></i>
          </div>
          <div className="annoucements">
            <h2 className="widget-title">Annoucements</h2>
            <Link to="/malaysiaairlines" className="annoucement-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
          </div>
          <div className="recent-posts">
            <h2 className="widget-title">Recent Posts</h2>
            <Link to="/malaysiaairlines" className="news-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
            <Link to="/pricing" className="news-link">
              RETHINKING THE PRICING DYNAMIC
            </Link>
            <Link to="/economics" className="news-link">
              AIRLINE ECONOMICS DURING THE TRANSITION TO A POST-PANDEMIC WORLD
            </Link>
            <Link to="/crisis" className="news-link">
              CRISIS AT THE SUMMIT – AVIATION’S SILVER LINING WITH A LIGHT AT
              THE END OF THE TUNNEL
            </Link>
          </div>
          <div className="recent-comments">
            <h2 className="widget-title">Recent Comments</h2>
          </div>
          <div className="categories">
            <h2 className="widget-title">Categories</h2>
            <Link to="/uncategorized" className="news-link">
              Uncategorized
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Economics;
