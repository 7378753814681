import React from "react";
import "./Number.css";
import {useEffect} from "react";
import AnimatedNumber from "animated-number-react";

function Number() {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <div className="num-container">
      <div className="number-container">
        <span className="numbers">
        $770
        </span>
        <span> Billion (2020)</span>
        <p className="text">Global Airline Market Value</p>
      </div>
      <div className="number-container">
        <span className="numbers">-60.9%</span>
        <span> (2020)</span>
        <p className="text">Passenger Air Traffic Growth</p>
      </div>
      <div className="number-container">
        <span className="numbers">$102.6+</span>
        <span> Billion</span>
        <p className="text">Operating Profit Commercial</p>
      </div>
    </div>
  );
}

export default Number;
