import React from "react";
import { Link } from "react-router-dom";
import analysis from "../../images/analysis.jpg"
import "./Analysis.css";
import {useEffect} from "react";

function Analysis() {
  useEffect(() => {
    window.scrollTo(0, 0)
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])
  return (
    <div className="analysis-container">
      <div
        className="analysis-banner-area"
        style={{ background: `url(${analysis})` }}
      >
        <div className="analysis-content container">
          <div className="analysis-links">
            <h2 className="analysis-h2">Analysis</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>analysis</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="analysis-content container">
        <div className="analysis-heading">
          <i class="fa fa-bar-chart"></i>
          <h2 className="analysis-title">Analysis</h2>
        </div>
        <p className="analysis-para">
          Using data analysis, enables you to understand what strategy have the
          biggest impact on the targeted entity and at what scale you can adopt
          such a move to Maximize. There is no activity that data analysis does
          better than to check the performance of your products or business in
          the market. Once you understand what products are suitable for what
          clients, you can determine the areas that you are going to focus on
          and for which customers. The trends in the market are also informative
          on consumer spending and tastes. When you have enough information on
          these vital things, you can direct your business to focus on certain
          products or services to fulfill your potential customer’s desires.
        </p>
      </div>
    </div>
  );
}

export default Analysis;
