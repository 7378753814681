import React from "react";
import about2 from "./images/about2.png";
import about3 from "./images/about3.png";
import "./Dorevemax.css";
import {useEffect} from "react";
import {Link} from "react-router-dom";

function Dorevemax() {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <div className="do-container container">
      <div className="img-container">
        <div className="image-container-1">
          <img className="about-img" src={about2} alt="about2" />
        </div>
        <div className="image-container-2">
          <img className="about-img" src={about3} alt="about2" />
        </div>
      </div>

      <div className="text-container">
        <h1>What can ReveMax do?</h1>
        <p>
          Our data-driven predictive models can help Airlines solve
          long-standing problems in new ways with increasing of Revenue. REVEMAX
          can optimize your capacity with robust ML and Algorithms to bring in
          ROI to drive Revenue with a Strategic Decision-Making solution
        </p>
        <Link to ="/arms" className="discover-btn">Discover More</Link>
      </div>
    </div>
  );
}

export default Dorevemax;
