import React from "react";
import aeroplane from "./images/aeroplane.png";
import globe from "./images/globe.png";
import pricing from "./images/pricing.png";
import "./IndustryArticle.css";
import { Link } from "react-router-dom";
import {useEffect} from "react";

function IndustryArticle() {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <div className="article-container container">
      <h1 id="industry-head">Industry Articles</h1>
      <p id="head-para">Check out our thoughts on the Airline Industry</p>
      <div className="article-content">
        <div className="aeroplane-container" id="aeroplane-container">
          <img src={aeroplane} alt="" className="article-img" />
          <div className="content-container">
            <p className="article-para">Nayeem Ekbal / March 20, 2020</p>
            <Link to="/crisis" className="article-link">
              CRISIS AT THE SUMMIT – AVIATION’S SILVER LINING WITH A LIGHT AT
              THE END OF THE TUNNEL
            </Link>
            <p className="article-para">
              Civil aviation has become a major industry in our time. Without
              air travel, mass international tourism would not exist..
            </p>
            <Link to="/crisis" className="read-btn">
              Read More <i class="fa fa-plus"></i>
            </Link>
          </div>
        </div>
        <div className="globe-container" id="globe-container">
          <img src={globe} alt="" className="article-img" />
          <div className="content-container">
            <p className="article-para">Hugh Dunleavy / January 20, 2021</p>
            <Link to="/economics" className="article-link">
              AIRLINE ECONOMICS DURING THE TRANSITION TO A POST-PANDEMIC WORLD
            </Link>
            <p className="article-para">
              Let us get right to the point ……. The airline industry is fragile
              due to many factors: · High fixed...
            </p>
            <Link to="/economics" className="read-btn">
              Read More <i class="fa fa-plus"></i>
            </Link>
          </div>
        </div>
        <div className="pricing-container" id="pricing-container">
          <img src={pricing} alt="" className="article-img" />
          <div className="content-container">
            <p className="article-para">Hugh Dunleavy / February 20, 2021</p>
            <Link to="/pricing" className="article-link">RETHINKING THE PRICING DYNAMIC</Link>
            <p className="article-para">
              re-pandemic, air travel in the USA was relatively expensive
              compared with travel on similar sector length international
              routes. The...
            </p>
            <Link to="/pricing" className="read-btn">
              Read More <i class="fa fa-plus"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndustryArticle;
