import React from "react";
import { Link } from "react-router-dom";
import "./MalaysiaAirlines.css";
import {useEffect} from "react";

function News() {
  useEffect(() => {
    window.scrollTo(0, 0)
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])
  return (
    <div className="news-container">
      <div className="news-banner-area" style={{ background: "black" }}>
        <div className="news-content container">
          <div className="news-links">
            <h2 className="news-h2">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
              </li>
              <li>
                <Link to="/news" className="home-link">
                  Blog
                </Link>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
              </li>
            </ul>
            <p> Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
                Strategy.</p>
          </div>
        </div>
      </div>

      <div className="news-content-container container">
        <div className="news-left-content">
          <p className="malaysia-para">
            <strong>Posted On:</strong> June 05, 2021{" "}
            <strong>Posted By:</strong><Link to="/admin">Admin</Link>
          </p>
          <p className="malaysia-para">
            https://www.malaysiaairlines.com/us/en/news-article/2021/malaysia-airlines-revemax.html
          </p>
          <p className="malaysia-para">
            <strong>
              <em>KLIA, 17 May 2021:</em>
            </strong>{" "}
            Malaysia Airlines is pleased to announce its partnership with
            ReveMax, a leading provider of Digital Strategic Solutions, to
            implement the Airline Revenue Maximization Solution (ARMS) which
            will enhance and strengthen the airline’s commercial decision-making
            and business processes.
          </p>
          <p className="malaysia-para">
            Powered by artificial intelligence (AI) and machine learning (ML),
            the platform provides predictive and prescriptive intelligence,
            connecting different airline departments and guiding them in making
            smarter strategic decisions with the aim of profitability and
            sustained financial good health of the organization.
          </p>
          <p className="malaysia-para">
            This partnership allows ReveMAX to bring its innovative ARMS
            Solution, a Strategic Decision Support Platform (SDSP), the first of
            its kind in the world to provide a complete and comprehensive
            picture of an airline’s revenue and cost ecosystem. The system also
            enables airline personnel to see a transparent, unadulterated, and
            360-degree bird’s-eye view of their airline’s day-to-day health and
            make an immediate revenue decision based on fact-based data.
          </p>
          <p className="malaysia-para">
            <strong>
              Group Chief Executive Officer of Malaysia Airlines Berhad, Izham
              Ismail
            </strong>{" "}
            <em>
              said, “One year into the COVID-19 pandemic, we have seen the
              importance of having a business that is robust and sustainable,
              built upon a foundation of digitalization which has allowed the
              business to operate as efficiently as possible given the
              circumstances. Malaysia Airlines is glad to be partnering with
              ReveMax and to be assisted by ARMS to create agility in maximizing
              revenue and optimizing cost with standardization and transparency
              across all related departments.
            </em>
          </p>
          <p className="malaysia-para">
            <em>
              This partnership offers strong unprecedented support for our
              business and core beliefs, and we look forward to enhancing
              customer experience with personalized offerings. Together with our
              other in-house system integrations, we can fully automate changes
              to our processes and trigger executions with limited manual
              interventions.”
            </em>
          </p>
          <p className="malaysia-para">
            <strong>Nayeem Ekbal, Chief Executive Officer of ReveMAX</strong>
            <em>
              {" "}
              said, “ReveMAX Solution uses artificial intelligence and machine
              learning to create a digital generation approach to advocate open
              and connected systems throughout the enterprise. This extends to
              integrations with other data sources and applications in the
              organization. We work with our partners to look at streamlining
              processes and bring in agility and transparency into the overall
              process of strategic decision-making and lifecycle management. We
              are proud to be partnering with Malaysian Airline and be part of
              their vision of Digital Transformation.”
            </em>
          </p>

          <p className="malaysia-para">
            <strong>Additional Coverage:</strong>
          </p>
          <p>
            <a href="https://www.thestar.com.my/business/business-news/2021/05/17/malaysia-airlines-revemax-in-revenue-strategy-plan">
              Malaysia Airlines, ReveMax in revenue strategy plan | The Star
            </a>
          </p>
          <p>
            <a href="https://www.ttgasia.com/2021/05/17/malaysia-airlines-teams-up-with-revemax-to-drive-revenue-growth/">
              Malaysia Airlines teams up with ReveMax to drive revenue growth |
              TTG Asia
            </a>
          </p>
          <p>
            <a href="https://www.nst.com.my/business/2021/05/690785/malaysia-airlines-partners-revemax-enhance-business-processes#.YKISum4np7o.facebook">
              Malaysia Airlines partners with ReveMax to enhance business
              processes (nst.com.my)
            </a>
          </p>
          <div className="comment-form-container">
            <p>
              <strong>Leave a Reply</strong>
            </p>
            <p>
              Your email address will not be published. Required fields are
              marked *
            </p>
            <form
              className="comment-form"
              action="https://revemax.ai/wp-comments-post.php"
              method="post"
              id="commentform"
              class="comment-form"
              novalidate=""
            >
              <div className="comment-section">
                <label htmlFor="comment">Comment</label>
                <textarea
                  className="textarea"
                  rows="5"
                  cols="40"
                  required
                ></textarea>
              </div>
              <div className="name-email">
                <div className="name-section">
                  <label htmlFor="name">Name *</label>
                  <input type="text" name="name" required />
                </div>
                <div className="email-section">
                  <label htmlFor="email">Email *</label>
                  <input type="email" name="email" required />
                </div>
              </div>
              <div className="website-section">
                <label htmlFor="website">Website</label>
                <input type="text" name="website" required />
              </div>
              <div className="checkbox-container">
                <input type="checkbox" id="check" />
                <p>
                  Save my name, email, and website in this browser for the next
                  time I comment
                </p>
              </div>
              <div className="post-btn">
                <input
                  type="SUBMIT"
                  className="comment-btn"
                  value="POST A COMMENT"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="news-right-content">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search here..."
              className="search-input"
            />
            <i class="fa fa-search"></i>
          </div>
          <div className="annoucements">
            <h2 className="widget-title">Annoucements</h2>
            <Link to="/malaysiaairlines" className="annoucement-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
          </div>
          <div className="recent-posts">
            <h2 className="widget-title">Recent Posts</h2>
            <Link to="/malaysiaairlines" className="news-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
            <Link to="/pricing" className="news-link">
              RETHINKING THE PRICING DYNAMIC
            </Link>
            <Link to="/economics" className="news-link">
              AIRLINE ECONOMICS DURING THE TRANSITION TO A POST-PANDEMIC WORLD
            </Link>
            <Link to="/crisis" className="news-link">
              CRISIS AT THE SUMMIT – AVIATION’S SILVER LINING WITH A LIGHT AT
              THE END OF THE TUNNEL
            </Link>
          </div>
          <div className="recent-comments">
            <h2 className="widget-title">Recent Comments</h2>
          </div>
          <div className="categories">
            <h2 className="widget-title">Categories</h2>
            <Link to="/uncategorized" className="news-link">
              Uncategorized
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
