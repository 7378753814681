import React from "react";
import { Link } from "react-router-dom";
import consulting from "../images/consulting.jpg";
import "../Consulting/Consulting.css";
import { useEffect } from "react";

function Consulting() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  }, []);

  
  return (
    <div className="consulting-container">
      <div
        className="consulting-banner-area"
        style={{ background: `url(${consulting})` }}
      >
        <div className="consulting-content container">
          <div className="consulting-links">
            <h2 className="consulting-h2">Consulting</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>consulting</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
      <div className="consulting-content-container container">
        <div className="consulting-contents">
          <i className="fa fa-users"></i>
          <Link to="/aviation" className="consulting-content-links">
            <h3 className="consulting-heading">Aviation Consulting</h3>
            <p className="consulting-para-btn">Read More</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Consulting;
