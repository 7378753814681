import React from "react";
import "./Quotes.css";
import shadeone from "./images/1.png";
import aero from "./images/aero.png";
import client from "./images/client.png";
import Carousel from "react-bootstrap/Carousel";
import {useEffect} from "react";

function Quotes() {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <div className="quotes-container quotes-bg">
       <Carousel fade>
        <Carousel.Item style={{ backgroundImage: 'url('+aero+')' }}>
          
          <Carousel.Caption className="quotes-content">
            <i class="fa fa-quote-left"></i>
            <p className="quotes-para">
              There is a single question that an airline needs to be able to
              answer when thinking about its pricing and revenue management
              function: “What is the airline’s overarching commercial strategy
              and how does this frame the actions required to sustain and/or
              improve revenue and yields?”
            </p>
            <h5>DELOITTE</h5>
          </Carousel.Caption>
          <img className="quotes-img aero-img" src={aero} alt="" />
        </Carousel.Item>
        <Carousel.Item style={{ backgroundImage: 'url('+client+')' }}>
          <Carousel.Caption className="quotes-content">
            <div className="caption-content">
                <i class="fa fa-quote-left"></i>
            <p className="quotes-para">
              Our Vision at ReveMax&nbsp; is to make Digital Transformation
              Universally accessible to the Airline Industry, that is our
              commitment and our goal
            </p>
            <h5>Gautam. R</h5>
            <p className="coo-para">COO</p>
            </div>
          </Carousel.Caption>
          <img className="quotes-img" src={client} alt="" />
        </Carousel.Item>
       </Carousel> 
    </div>
  );
}

export default Quotes;
