import React from "react";
import Number from "../Numbers/Number";
import Dorevemax from "../RevemaxDO/Dorevemax";
import BannerCarousel from "../BannerCarousel/BannerCarousel";
import Solution from "../Solution/Solution";
import Video from "../Video/Video";
import Ecosystem from "../Ecosystem/Ecosystem";
import IndustryArticle from "../IndustryArticles/IndustryArticle";
import Reactout from "../Reactout/Reactout";
import Quotes from "../Quotes/Quotes";
import {useEffect} from "react";


function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
    const preloader = document.querySelector('.preloader');
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])
  return (
    <div>
      <BannerCarousel/>
      <Number />
      <Dorevemax />
      <Solution />
      <Video />
      <Ecosystem />
      <Quotes/>
      <IndustryArticle />
      <Reactout />
    </div>
  );

}

export default Home;
