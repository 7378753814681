import React from "react";
import { Link } from "react-router-dom";
import "./Preferredseat.css";
import preferredseat from "../../images/preferredseat.jpg";
import {useEffect} from "react";

function Preferredseat() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])

  return (
    <div className="preferredseat-container">
      <div
        className="preferredseat-banner-area"
        style={{ background: `url(${preferredseat})` }}
      >
        <div className="preferredseat-content container">
          <div className="preferredseat-links">
            <h2 className="preferredseat-h2">Preferred seat option</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>Preferred seat option</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="preferredseat-content container">
        <div className="preferredseat-heading">
          <i class="fa fa-thumbs-up"></i>
          <h2 className="preferredseat-title">Preferred seat option</h2>
        </div>
        <p className="preferredseat-para">
          The solution enables airline to capitalize revenues from advanced seat
          assignments for preferred seats based on airline strategy. The
          distress inventory seat can generate more revenues without any impact
          to your regular seat assignment sales and enhances travel experience
          for your customers at an affordable price.
        </p>
      </div>
    </div>
  );
}

export default Preferredseat;
