import React from "react";
import { Link } from "react-router-dom";
import arms from "../images/arms.jpg"
import "./ARMS.css";
import {useEffect} from "react";

function ARMS() {
  useEffect(() => {
    window.scrollTo(0, 0)
    const preloader = document.querySelector('.preloader');
    preloader.className = 'preloader preloader-deactivate';

    return () => { preloader.className = 'preloader'; }
  },[])
  return (
    <div className="arms-container">
      <div className="arms-banner-area" style={{ background: `url(${arms})` }}>
        <div className="arms-content container">
          <div className="arms-links">
            <h2 className="arms-h2">
              Airline Revenue Maximization Solution (ARMS)
            </h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home &nbsp; &nbsp;<i class="fa fa-angle-right"></i>
                </Link>
              </li>
              <li>
    
              </li>
              <li>Airline Revenue Maximization Solution (ARMS)</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
      <div className="arms-content-container container">
        <div className="arms-contents">
          <i class="fa fa-search"></i>
          <Link to="/prediction" className="arms-content-links">
            <h3 className="arms-heading">Predictions</h3>
            <p className="arms-para">Read More</p>
          </Link>
        </div>
        <div className="arms-contents">
          <i class="fa fa-bar-chart"></i>
          <Link to="/analysis" className="arms-content-links">
            <h3 className="arms-heading">Analysis</h3>
            <p className="arms-para">Read More</p>
          </Link>
        </div>
        <div className="arms-contents">
          <i class="fa fa-pie-chart"></i>
          <Link to="/reporting" className="arms-content-links">
            <h3 className="arms-heading">Reporting</h3>
            <p className="arms-para">Read More</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ARMS;
